import { Box, Grid } from '@material-ui/core';
import React, { useContext, useEffect, useState } from 'react';
import ReactCodeInput from 'react-code-input';
import { useTranslation } from 'react-i18next';
import eventTrackingService from '../../../services/eventtracking.service';
import pinRequestService from '../../../services/pinRequest.service';
import '../../../styles/components/tabs.scss';
import Toaster from '../../alerts/Toaster';
import { ApiContext } from '../useLogin';

const MAX_WAIT_TIME = 60;

export const VerifyPin = (props) => {
    const { request, number, pin, setPin, setLoading } = useContext(ApiContext);
    const { recaptcha, captchaToken, captchaKey } = props;
    const [seconds, setSeconds] = useState(MAX_WAIT_TIME);
    const [validationCodeSentSms, setValidationCodeSentSms] = useState(false);
    const [retryCodeSentSms, setRetryCodeSentSms] = useState(false);
    // Error handling
    const [message, setMessage] = useState('');
    const [open, setOpen] = useState(false);
    const { t } = useTranslation();
    // Analytics
    const analyticsStep = 2;
    // cuit handling
    const { clientNumber } = number;
    const { cuit } = request;
    const [secret, setSecret] = useState(null);
    const VALIDATION_PIN_SEND_EVENT = 'enviar código de validación sms';

    useEffect(() => {
        setPin({ clientPin: null });
        setSecret('');
    }, []);

    useEffect(() => {
        if (captchaToken) {
            handleSendPin(captchaToken);
        } else if(!captchaKey) {
            handleSendPin(null);
        }
    }, [captchaToken]);

    useEffect(() => {
        window.scrollTo({
            top: 500,
            behavior: 'smooth',
        });
    }, [validationCodeSentSms]);

    useEffect(() => {
        if (validationCodeSentSms) {
            const timer = setInterval(() => {
                setSeconds(seconds - 1);
            }, 1000);
            return () => clearInterval(timer);
        }
    });

    useEffect(() => {
        if (seconds && seconds <= 0) {
            setRetryCodeSentSms(true);
            setValidationCodeSentSms(false);
            setSeconds(MAX_WAIT_TIME);
        }
    }, [seconds]);

    const handleChangePin = (value) => {
        setPin({
            clientPin: value,
        });
    };

    const resetCaptcha = () => {
        if (captchaToken) {
            recaptcha.current.execute();
        } else if(!captchaKey) {
            handleSendPin(null);
        }
    };
    const ShowToaster = (props) => {
        return <Toaster elevation={6} variant="filled" {...props} />;
    };

    const handleToasterClose = () => {
        setOpen(false);
    };

    const handleSendPin = async (tkn) => {
        eventTrackingService.trackEvent(VALIDATION_PIN_SEND_EVENT, null, null, analyticsStep);
        setLoading(true);
        await pinRequestService
            .createRequest(`${cuit}`, `${clientNumber}`, tkn)
            .then((pinResponse) => {
                if (pinResponse.data) {
                    setPin({ clientPin: pinResponse.data });
                    setSecret(`[${pinResponse.data}]`);
                } else {
                    setPin({ clientPin: '' });
                    setSecret('');
                }
                setValidationCodeSentSms(true);
                setSeconds(MAX_WAIT_TIME);
            })
            .catch(function (error) {
                setPin({ clientPin: '' });
                setSecret('');
                setValidationCodeSentSms(false);
                setMessage(t(`WORKFLOW_ERROR.ERROR_SENDING_SMS.DESCRIPTION`));
                setRetryCodeSentSms(true);
                setOpen(true);
            })
            .finally(() => {
                setLoading(false);
            });
    };

    return (
        <>
            <Box className="mt-2-5">
                <Grid container direction="row">
                    <Grid item xs={12}>
                        <h4 className="formEntryLabel">Ingresá el código que te enviamos por SMS {secret}</h4>
                        {validationCodeSentSms && <span className="fs-14">(Aguardá {seconds} segundos para reenviar)</span>}
                    </Grid>

                    {retryCodeSentSms && !validationCodeSentSms && (
                        <Grid item xs={12}>
                            <Grid item xs={12}>
                                <Box className="mt-1">
                                    <a href="#" className="link fs-14 mt-1 fw-400 color-link-blue" onClick={resetCaptcha}>
                                        Reenviar CÓDIGO
                                    </a>
                                </Box>
                            </Grid>
                        </Grid>
                    )}

                    {validationCodeSentSms && (
                        <Grid item xs={12} sm={8} lg={8}>
                            <ReactCodeInput
                                fields={4}
                                style={{ marginTop: '16px' }}
                                onChange={handleChangePin}
                                value={pin.clientPin}
                                autoComplete="false"
                                filterChars="qwertyuiopasdfghjklñzxcvbnm, .-QWERTYUIOPASDFGHJKLÑZXCVBNM{}[]+´|@"
                                inputMode="numeric"
                            />
                        </Grid>
                    )}
                </Grid>
            </Box>
            <ShowToaster open={open} textToShow={message} type="error" handleToasterClose={handleToasterClose} />{' '}
        </>
    );
};
